<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title> Предложения </b-card-title>
        <div>
          <b-button
            pill
            variant="light"
            :to="{ name: 'admin-offers-companies' }"
            >Компании</b-button
          >
          <!-- <b-button 
                    class="ml-1"
                    pill 
                    variant="light" 
                    :to="{ name: 'admin-offers-categories'}"
                >Категории</b-button> -->
          <b-button
            class="ml-1"
            pill
            variant="primary"
            :to="{
              name: 'admin-offers-add',
              params: {
                categories: categories,
                companies: companies,
                cities: cities,
                typeAllocation: type_allocation,
                typesSelectedCities: types_selected_cities,
                statesAccess: states_access,
                integrations: integrations,
              },
            }"
            >Создать</b-button
          >
        </div>
      </b-card-header>

      <b-skeleton-table
        v-if="is_loading"
        animation="fade"
        :columns="7"
        :rows="5"
      >
      </b-skeleton-table>

      <b-table-simple v-else class="font-small-3 text-black" responsive>
        <b-thead>
          <b-tr>
            <b-td>#</b-td>
            <b-td>Заголовок</b-td>
            <b-td>Категория</b-td>
            <b-td
              >Расположение <br />
              на главной</b-td
            >
            <b-td>Url</b-td>
            <b-td>Интеграции</b-td>
            <b-td>Описание</b-td>
            <b-td>Дата создания</b-td>
            <b-td></b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in items" :key="item.id">
            <b-td class="text-black">{{ item.id }}</b-td>
            <b-td class="text-black">{{ item.title }}</b-td>
            <b-td class="text-black">{{ item.has_category.name }}</b-td>
            <b-td class="text-black">{{
              type_allocation.find((t) => t.id == item.showcase_location).title
            }}</b-td>

            <b-td class="text-black"
              ><span v-b-tooltip.hover :title="item.url">{{
                Utils.startAndEnd(item.url)
              }}
              </span></b-td
            >
            <b-td class="text-black">
              <ul class="list-unstyled">
                <li
                  style="display: inline-block; margin-right: 4px; margin-bottom: 4px"
                  v-for="(integration, index) in item.integrations"
                  :key="index"
                >
                  <b-badge
                    variant="secondary"
                    class="fs-12"
                    style="
                      border-radius: 50px;
                      padding: 2px 6px 2px 6px;
                      font-weight: 400;
                      margin-right: 4px;
                    "
                  >
                    {{ integration.name }}
                  </b-badge>
                </li>
                <li
                  v-if="item.integrations.length == 0"
                  style="display: inline-block; margin-right: 4px"
                >
                  <b-badge
                    variant="warning"
                    class="fs-12"
                    style="
                      border-radius: 50px;
                      padding: 2px 6px 2px 6px;
                      font-weight: 400;
                    "
                  >
                    Нет интеграции
                  </b-badge>
                </li>
              </ul>
            </b-td
            >
            <b-td class="text-black"
              ><span v-b-tooltip.hover :title="item.description">{{
                Utils.startAndEnd(item.description)
              }}</span></b-td
            >

            <b-td class="text-black text-nowrap"
              >{{ item.date }} <br /><span class="text-muted">{{
                item.time
              }}</span></b-td
            >
            <b-td>
              <b-dropdown size="sm" right no-caret variant="transparent">
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="text-muted"
                    size="22"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'admin-offers-update',
                    params: {
                      offer: item,
                      categories: categories,
                      companies: companies,
                      cities: cities,
                      typeAllocation: type_allocation,
                      typesSelectedCities: types_selected_cities,
                      statesAccess: states_access,
                      integrations: integrations,
                    },
                  }"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="text-primary"
                    size="13"
                  />
                  <span class="text-primary"> Редактировать</span>
                </b-dropdown-item>
                <b-dropdown-item @click="preferDeleteAction(item, index)">
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger"
                    size="13"
                  />
                  <span class="text-danger"> Удалить</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="preferRevertAction(item, index)">
                  <feather-icon
                    icon="RotateCcwIcon"
                    class="text-warning"
                    size="13"
                  />
                  <span class="text-warning">Восстановить</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-card-body class="d-flex justify-content-between pt-0 pb-0">
        <span class="font-weight-bolder">Всего: {{ total_count }}</span>

        <b-pagination-nav
          :value="current_page"
          :link-gen="linkGen"
          :number-of-pages="last_page"
          use-router
        ></b-pagination-nav>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Utils from "@/modules/utils";

export default {
  data() {
    return {
      Utils,
      is_loading: true,
      items: [],
      current_page: 0,
      has_more_pages: false,
      last_page: 0,
      total_count: 0,
      categories: [],
      companies: [],
      cities: [],
      integrations: [],
      type_allocation: [
        { id: "main", title: "Общий раздел" },
        { id: "top", title: "Верхний раздел" },
        { id: "carousel", title: "Карусель" },
      ],
      types_selected_cities: [
        { id: "single", title: "Все выбранные" },
        { id: "everything_except", title: "Все кроме выбранного" },
        { id: "list", title: "Список (через запятую)" },
      ],
      states_access: [
        { id: "open", title: "Открытый" },
        { id: "order", title: "Закрытый (Доступ по заявке)" },
      ]
    };
  },
  methods: {
    preferDeleteAction(item, index) {
      this.$swal({
        title: `Подтвердите действие`,
        text: `Вы действительно хотите удалить предложение '${item.title}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$request
            .post("offers/delete", {
              id: item.id,
            })
            .then((rsp) => {
              this.items.splice(index, 1);
              this.$swal({
                icon: "success",
                title: item.title,
                text: "Предложение успешно удален",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    preferRevertAction(item, index) {
      this.$swal({
        title: `Подтвердите действие`,
        text: `Вы действительно хотите восстановить предложение '${item.title}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$request
            .post("offers/revert", {
              id: item.id,
            })
            .then((rsp) => {
              this.items.splice(index, 1);
              this.$swal({
                icon: "success",
                title: item.title,
                text: "Предложение успешно восстановлено",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: {
          page: pageNum,
        },
      };
    },

    get() {
      this.is_loading = true;

      this.$request.get("offers/list", this.$route.query).then((rsp) => {
        this.items = rsp.items;
        this.categories = rsp.categories;
        this.companies = rsp.companies;
        this.cities = rsp.cities;
        this.integrations = rsp.integrations;
        this.total_count = rsp.total_count;
        this.current_page = rsp.current_page;
        this.last_page = rsp.last_page;
        this.is_loading = false;
      });
    },
  },
  components: {},
  watch: {
    "$route.query": function () {
      this.get();
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
